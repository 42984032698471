/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { toast } from "react-toastify"
import { navigate } from "gatsby"
import axios from "axios"
import NumberFormat from "react-number-format"
import { formatPrice } from "utils/mapProductsFormAnalytics"

import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import Button from "components/Button/Button"
import { AppContext } from "components/Context/AppContext"

import validateAndSanitizeCheckoutForm from "validator/checkout"
import {
  getFormattedCart,
  createCheckoutData,
  getPaymentMethod,
} from "utils/functions"
import GET_CART from "queries/get-cart"
import CHECKOUT_MUTATION from "mutations/checkout"

const CheckoutSummary = () => {
  const isBrowser = typeof window !== "undefined"
  const [cart, setCart] = useContext(AppContext)
  const [input, setInput] = useState(
    isBrowser && JSON.parse(localStorage.getItem("woo-order-summary"))
  )
  const [inpost, setInpost] = useState(
    isBrowser && JSON.parse(localStorage.getItem("woo-order-inpost"))
  )
  const [orderData, setOrderData] = useState(null)

  const totalParsed = parseInt(
    cart?.totalSubProductsPrice.match(/\d|\./g).join("")
  )

  // Get Cart Data
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      const updatedCart = getFormattedCart(data)
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
      setCart(updatedCart)
    },
    onError: error => {
      console.warn(error)
    },
  })

  // Checkout or CreateOrder Mutation.
  const [checkout, { loading: checkoutLoading }] = useMutation(
    CHECKOUT_MUTATION,
    {
      variables: {
        input: orderData,
      },
      onCompleted: async ({ checkout: { order } }) => {
        window.fbq("track", "Purchase", {
          content_type: "product",
          content_ids: order.lineItems.nodes.map(
            item => item.product.databaseId
          ),
          value: formatPrice(order.total),
          currency: "PLN",
        })
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          ecommerce: {
            purchase: {
              actionField: {
                id: order?.databaseId,
                affiliation: "Online Store",
                revenue: formatPrice(order.total),
                tax: 0.23,
                shipping: formatPrice(order.shippingTotal),
                coupon: "",
              },
              products: order.lineItems.nodes.map(item => ({
                id: item.product.databaseId,
                name: item.product.name,
                brand:
                  item.product.paProducents?.nodes
                    ?.map(({ name }) => name)
                    .join(",") || "",
                category:
                  item.product.productCategories?.nodes
                    ?.map(({ name }) => name)
                    .join(",") || "",
                price: parseFloat(item.total) + parseFloat(item.totalTax),
                quantity: item.quantity,
                currency: "PLN",
              })),
            },
          },
        })
        if (order?.paymentMethod === "przelewy24") {
          try {
            const total_parsed =
              parseInt(order.total.match(/\d|\./g).join("")) * 100 // musi być jako number w groszach
            const {
              data: { payment_url },
            } = await axios.post("https://api.memtech.pl/payments", {
              sessionId: order?.databaseId.toString(),
              amount: total_parsed,
              description: `Oplata za zamowienie numer ${order.orderNumber}`,
              email: input?.email,
              client: input?.firstName + " " + input?.lastName,
              currency: "PLN",
              urlReturn: "https://memtech.pl/potwierdzenie/",
            })
            await refetch()
            localStorage.removeItem("woo-order-summary")
            localStorage.removeItem("woo-order-inpost")
            window.location.href = payment_url
          } catch (error) {
            toast.error("Wystąpił błąd")
            console.warn(error.response.data)
          }
        } else {
          await refetch()
          localStorage.removeItem("woo-order-summary")
          localStorage.removeItem("woo-order-inpost")
          navigate("/potwierdzenie/")
        }
      },
      onError: error => {
        if (error) {
          toast.error("Wystąpił błąd")
          console.warn(error.graphQLErrors[0].message)
        }
      },
    }
  )

  // Handle form submit.
  const handleFormSubmit = e => {
    e.preventDefault()
    const result = validateAndSanitizeCheckoutForm(input)
    if (!result.isValid) {
      setInput({ ...input, errors: result.errors })
      setInpost({ ...inpost, errors: result.errors })
      console.warn(result.errors)
      toast.error("Wystąpił błąd")
      return
    }
    const checkOutData = createCheckoutData(input, inpost)
    setOrderData(checkOutData)
  }

  useEffect(() => {
    if (null !== orderData) {
      checkout()
    }
  }, [orderData])

  const chosen_shipping_method = data?.cart?.chosenShippingMethods?.[0]
  const chosen_shipping_method_data =
    data?.cart?.availableShippingMethods?.[0]?.rates.find(
      ({ id }) => id === chosen_shipping_method
    )

  return cart ? (
    <form onSubmit={handleFormSubmit}>
      <div className="row">
        <div className="col-lg-8">
          <PageSubHeader title="Produkty" />
          <div className="order__table">
            <div className="order__table-header">
              <div className="row">
                <div className="col-md-7">
                  <small>Nazwa</small>
                </div>
                <div className="col-md-2">
                  <small>Liczba sztuk</small>
                </div>
                <div className="col-md-2">
                  <small>Kwota (brutto)</small>
                </div>
              </div>
            </div>
            {cart &&
              cart.products.map((item, index) => (
                <div className="order__table-item" key={index}>
                  <div className="row align-items-center">
                    <div className="col-1">
                      <div className="cart__table-item-image">
                        {item.image && (
                          <img src={item.image.sourceUrl} alt={item.name} />
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <span className="order__table-mobile-label">Nazwa</span>
                      {item.name}
                    </div>
                    <div className="col-md-2">{item.qty} szt.</div>
                    <div className="col-md-2">{item.totalPrice}</div>
                  </div>
                </div>
              ))}
          </div>
          <PageSubHeader
            className="page__sub-header--less-m"
            title="Sposób dostawy"
          />
          <div className="row">
            <div className="col-md-12">
              {chosen_shipping_method_data?.label}{" "}
              {!!chosen_shipping_method_data?.cost &&
                `(+${parseInt(chosen_shipping_method_data.cost)}zł)`}
            </div>
          </div>
          {inpost && (
            <div className="row">
              <div className="col-12">Wybrany Paczkomat: {inpost.name}</div>
            </div>
          )}
          {chosen_shipping_method === "local_pickup:11" && (
            <div className="order__pickup-info">
              Adres odbioru zamówień: ul. Beskidzka 2a, 34-322 Rychwałd
            </div>
          )}
          <PageSubHeader
            className="page__sub-header--less-m"
            title="Metoda płatności"
          />
          <div className="row">
            <div className="col-md-7">
              {getPaymentMethod(input?.paymentMethod)}
            </div>
          </div>
          <PageSubHeader
            className="page__sub-header--less-m"
            title="Dane zamawiającego"
          />
          <address>
            {input.firstName} {input.lastName} <br />
            {input.address1} {input.address2} <br />
            {input.postalcode} {input.city} <br />
            {input.phone} <br />
            {input.email}
          </address>
          {input.invoice && (
            <>
              <PageSubHeader
                className="page__sub-header--less-m"
                title="Dane do faktury"
              />
              <address>
                {input.companyName} <br />
                NIP: {input.companyNIP}
                <br />
                {input.companyAddress1} {input.companyAddress2} <br />
                {input.companyPostcode} {input.companyCity}
              </address>
            </>
          )}
        </div>

        <div className="col-xl-3 offset-xl-1 col-lg-4">
          <div className="order__summary">
            <div className="order__summary-info">
              <div className="row">
                <div className="col-6">Kwota produktów:</div>
                <div className="col-6">{data?.cart?.subtotal}</div>
              </div>
              <div className="row">
                <div className="col-6">Koszt dostawy:</div>
                <div className="col-6">
                  {chosen_shipping_method_data?.label}{" "}
                  {!!chosen_shipping_method_data?.cost &&
                    `(+${parseInt(chosen_shipping_method_data.cost)}zł)`}
                </div>
              </div>
              <div className="row">
                <div className="col-6">Metoda płatności:</div>
                <div className="col-6">
                  {getPaymentMethod(input?.paymentMethod)}
                </div>
              </div>
            </div>
            <p>Łączna kwota zamówienia</p>
            <h3>
              <NumberFormat
                value={data?.cart?.total}
                displayType="text"
                thousandSeparator=","
                decimalSeparator="."
                suffix=".00zł"
              />
            </h3>
            <div className="order__summary-shipping-info">
              <p>
                {totalParsed < 2000 ? (
                  <>
                    Do darmowej wysyłki <br /> brakuje Ci{" "}
                    <NumberFormat
                      value={2000 - totalParsed}
                      displayType="text"
                      thousandSeparator=","
                      decimalSeparator="."
                      suffix=".00zł"
                    />
                  </>
                ) : (
                  "Osiągnąłeś próg darmowej wysyłki!"
                )}
              </p>
            </div>
            <Button type="submit">
              {checkoutLoading ? "Składanie zamówienia..." : "Złóż zamówienie"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  ) : (
    <p className="row__products-empty-info row__products-empty-info--margin">
      Aby złożyć <strong>zamówienie</strong> proszę dodać produkty do koszyka.
    </p>
  )
}

export default CheckoutSummary
